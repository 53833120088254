.mainContainer {
  display: flex;
  min-height: 100vh;
  width: 100vw;
}

.halfContainer {
  height: auto;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.leftContainer {
  background-color: #C8AF55;

}

.rightContainer {
  background-color: #5C4033;
}

.teamName {
  font-size: 30px;
  font-weight: 800;
}

.accordianContainer {
  width: 100%;
  border-radius: 0 !important;
  background-color: #101113 !important;
  color: #A0A1A2 !important;
}

.flexRowReverse {
  flex-direction: row-reverse !important;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexApart {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fullWidth {
  width: 100%
}
.oneOfThreeSections {
  width: 33%
}

.oneOfTwoSections {
  width: 50%
}

.rightTextAlign {
  text-align: right;
}

.centerTextAlign {
  text-align: center;
}

.boldFont {
  font-weight: bold !important;
}

.redFont {
  color: red;
}

.greenFont {
  color: green
}
.blueFont {
  color: blue;
}

.avatar {
  width: 50%
}
svg {
  color: #A0A1A2 !important;
}

.sixSidePadding {
  padding: 0 6px !important
}